<template>
  <div class="wrap">
    <head-nav></head-nav>
    <div class="con-wrap">
      <div class="banner">
        <b-container class="banner-con">
          <b-row class="b-row">
            <b-col lg="8" xl="9">
              <div class="banner-tip">
                <h1>物流配送</h1>
                <div class="sub-title">高效、便捷、安全，运输业务辐射全国，综合运输实力强大。</div>
              </div>
            </b-col>
            <b-col lg="4" xl="3">
              <div class="service-phone">
                <i></i>
                <span>400-6122-916</span>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="service-items-wrap">
        <b-container class="service-items-con">
          <b-row>
            <b-col class="b-col" sm="12" md="4" lg="4" xl="4">
              <div class="service-item">
                <div :class="activeNum == 0 ? 'service-item-con active' : 'service-item-con'" @click="changeServiceDesc(0)">
                  <i class="transportation-services"></i>
                  <span>运输服务</span>
                </div>
              </div>
            </b-col>
            <b-col class="b-col" sm="12" md="4" lg="4" xl="4">
              <div class="service-item">
                <div :class="activeNum == 1 ? 'service-item-con active' : 'service-item-con'" @click="changeServiceDesc(1)">
                  <i class="installation-services"></i>
                  <span>安装服务</span>
                </div>
              </div>
            </b-col>
            <b-col class="b-col" sm="12" md="4" lg="4" xl="4">
              <div class="service-item">
                <div :class="activeNum == 2 ? 'service-item-con active' : 'service-item-con'" @click="changeServiceDesc(2)">
                  <i class="five-guarantees-service"></i>
                  <span>五包服务</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="transportation-services" v-if="activeNum == 0">
        <b-container>
          <b-row>
            <b-col>
              <div class="transportation-services-con">
                <el-form :model="form" :inline="true" size="small" label-width="80px">
                  <el-form-item label="起点：">
                    <el-input disabled v-model="form.start" suffix-icon="el-icon-caret-bottom"></el-input>
                  </el-form-item>
                  <el-form-item label="卸点：">
                    <!-- <el-input v-model="form.end" suffix-icon="el-icon-caret-bottom" placeholder="请选择卸点"></el-input>
                    <div class="address-wrap">
                      <i class="close el-icon-close"></i>
                      <div class="select-addr-wrap">
                        <span>请选择 <i class="el-icon-arrow-down"></i></span>
                        <span>请选择 <i class="el-icon-arrow-down"></i></span>
                      </div>
                      separator="-"
                    </div> -->
                    <el-cascader :options="options" clearable @change="changeAddr" :props="{ value: 'label' }" ref="cascader"></el-cascader>


                  </el-form-item>
                  <el-form-item label="方数：">
                    <el-input v-model="form.squareNumber"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="warning" @click="query">查询报价</el-button>
                  </el-form-item>
                </el-form>
                <p>尊敬的客户：您好！此报价为历史成交平均价格，仅作参考。若想获取准确的实时价格，或有特殊运输要求，请联系您的业务对接人或拨打400-6122-916。</p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="installation-services" v-if="activeNum == 1">
        <img src="@/../public/icon/mall/logisticsDistribution/freight-list.jpg" alt="">
        <p>尊敬的客户：您好！此报价为历史成交平均价格，仅作参考。若想获取准确的实时价格，或有特殊运输要求，请联系您的业务对接人或拨打400-6122-916。</p>
      </div>
      <div class="five-guarantees-service" v-if="activeNum == 2">
        <img src="@/../public/icon/mall/logisticsDistribution/transport.jpg" alt="">
        <p>尊敬的客户：您好！五包服务中所含服务较多，若想获取准确的实时价格，请联系您的业务对接人或拨打400-6122-916。</p>
      </div>
      <div class="service-floor floor-a">
        <b-container>
          <b-row>
            <b-col>
              <div class="service-floor-tip">
                <h2>
                  <span>运输服务</span>
                </h2>
                <div class="service-desc">
                  <span>运输业务辐射全国，运营超过2500条优势公路线路，并已开通数条水运线路，综合运输实力强大。</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="service-floor floor-b">
        <b-container>
          <b-row>
            <b-col>
              <div class="service-floor-tip">
                <h2>
                  <span>安装服务</span>
                </h2>
                <div class="service-desc">
                  <span>行业首选、品质保证，专业资质认证，技术精湛，层层考核，高标准服务流程。</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <footer-nav></footer-nav>
    <el-dialog title="运输报价" :visible.sync="openDialog" width="30%" center>
      <div class="transportation-quotation">
        <span>起点：{{form.start}}</span>
        <span>卸点：{{form.end}}</span>
        <span>方数：{{form.squareNumber}}</span>
      </div>
      <div class="freight">运输费用：<span>￥{{(form.squareNumber*form.price).toFixed(2)}}</span></div>
      <div class="tip">此报价为历史成交平均价格，仅供参考</div>
    </el-dialog>
  </div>
</template>

<script>
  import headNav from "@/components/Navbar";
  import footerNav from "@/components/Footer";
  export default {
    components: {
      headNav,
      footerNav
    },
    data() {
      return {
        form: {
          start: "广东-佛山",
          price: 0,
          squareNumber: "",
          end: "",
        },
        activeNum: -1, 
        openDialog: false,
        options: [{
            label: '广东省',
            children: [{
                label: '广州市',
                price: '80',
                time: '2~3天'
              },
              {
                label: '深圳市',
                price: '80',
                time: '2~3天'
              },
              {
                label: '珠海市',
                price: '80',
                time: '2~3天'
              },
              {
                label: '汕头市',
                price: '80',
                time: '2~3天'
              },
              {
                label: '韶关市',
                price: '120',
                time: '3~4天'
              },
              {
                label: '中山市',
                price: '80',
                time: '2~3天'
              },
              {
                label: '湛江市',
                price: '120',
                time: '3~4天'
              },
            ]
          },
          {
            label: '云南省',
            children: [{
                label: '昆明市',
                price: '120',
                time: '3~5天'
              },
              {
                label: '曲靖市',
                price: '150',
                time: '5~7天'
              },
              {
                label: '玉溪市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '保山市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '昭通市',
                price: '260',
                time: '5~7天'
              },
              {
                label: '丽江市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '普洱市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '临沧市',
                price: '260',
                time: '5~7天'
              },
              {
                label: '安宁市',
                price: '260',
                time: '5~7天'
              },
              {
                label: '宣威市',
                price: '240',
                time: '5~7天'
              },
              {
                label: '文山市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '个旧市',
                price: '260',
                time: '5~7天'
              },
              {
                label: '开远市',
                price: '240',
                time: '5~7天'
              },
              {
                label: '景洪市',
                price: '240',
                time: '5~7天'
              },
              {
                label: '楚雄市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '大理市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '潞西市',
                price: '260',
                time: '5~7天'
              },
              {
                label: '瑞丽市',
                price: '260',
                time: '5~7天'
              },
            ]
          },
          {
            label: '河北省',
            children: [{
                label: '石家庄',
                price: '150',
                time: '3~5天'
              },
              {
                label: '唐山市',
                price: '180',
                time: '3~5天'
              },
              {
                label: '邯郸市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '保定市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '承德市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '廊坊市',
                price: '180',
                time: '3~5天'
              },
              {
                label: '秦皇市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '张家口',
                price: '220',
                time: '5~7天'
              },
              {
                label: '遵化市',
                price: '220',
                time: '5~7天'
              },
            ]
          },
          {
            label: '山东省',
            children: [{
                label: '青岛市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '济南市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '泰安市',
                price: '180',
                time: '3~5天'
              },
              {
                label: '临沂市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '烟台市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '威海市',
                price: '180',
                time: '3~5天'
              },
              {
                label: '济南市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '潍坊市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '日照市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '聊城市',
                price: '180',
                time: '3~5天'
              },
            ]
          },
          {
            label: '内蒙古',
            children: [{
                label: '呼和浩特',
                price: '200',
                time: '5~7天'
              },
              {
                label: '临河市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '赤峰市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '乌兰浩特',
                price: '260',
                time: '7~10天'
              },
              {
                label: '满洲里',
                price: '280',
                time: '7~10天'
              },
            ]
          },
          {
            label: '青海',
            children: [{
                label: '西宁市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '格尔木',
                price: '380',
                time: '10~12天'
              },
              {
                label: '德令哈',
                price: '380',
                time: '10~12天'
              },
            ]
          },
          {
            label: '广西省',
            children: [{
                label: '南宁市',
                price: '100',
                time: '2~3天'
              },
              {
                label: '柳州市',
                price: '100',
                time: '2~3天'
              },
              {
                label: '桂林市',
                price: '100',
                time: '2~3天'
              },
              {
                label: '玉林市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '钦州市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '贵港市',
                price: '120',
                time: '3~5天'
              },
              {
                label: '北海市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '河池市',
                price: '150',
                time: '3~5天'
              },
            ]
          },
          {
            label: '贵州省',
            children: [{
                label: '贵阳市',
                price: '120',
                time: '3~5天'
              },
              {
                label: '六盘水',
                price: '150',
                time: '3~5天'
              },
              {
                label: '遵义市',
                price: '180',
                time: '4~6天'
              },
              {
                label: '安顺市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '清镇市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '赤水市',
                price: '180',
                time: '4~6天'
              },
              {
                label: '仁怀市',
                price: '180',
                time: '4~6天'
              },
              {
                label: '铜仁市',
                price: '150',
                time: '4~6天'
              },
              {
                label: '毕节市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '兴义市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '凯里市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '都匀市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '福泉市',
                price: '220',
                time: '4~6天'
              },
            ]
          },
          {
            label: '甘肃省',
            children: [{
                label: '兰州市',
                price: '170',
                time: '5~7天'
              },
              {
                label: '天水市',
                price: '260',
                time: '7~10天'
              },
              {
                label: '酒泉市',
                price: '260',
                time: '7~10天'
              },
              {
                label: '庆阳市',
                price: '260',
                time: '5~7天'
              },
            ]
          },
          {
            label: '甘肃省',
            children: [{
                label: '太原市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '临汾',
                price: '160',
                time: '5~7天'
              },
              {
                label: '长治市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '晋城市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '运城市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '孝义',
                price: '200',
                time: '5~7天'
              },
              {
                label: '阳泉',
                price: '200',
                time: '5~7天'
              },
            ]
          },
          {
            label: '陕西省',
            children: [{
                label: '西安市',
                price: '150',
                time: '5~7天'
              },
              {
                label: '咸阳市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '铜川市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '延安市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '汉中市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '商洛市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '榆林市',
                price: '200',
                time: '5~7天'
              },
              {
                label: '韩城市',
                price: '260',
                time: '5~7天'
              },
            ]
          },
          {
            label: '江苏省',
            children: [{
                label: '南京市',
                price: '120',
                time: '3~5天'
              },
              {
                label: '无锡市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '常州市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '苏州市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '扬州市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '泰州市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '徐州市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '盐城市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '南通市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '宿迁市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '镇江市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '连云港',
                price: '180',
                time: '5~7天'
              },
            ]
          },
          {
            label: '四川省',
            children: [{
                label: '成都市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '绵阳市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '德阳市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '广元市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '自贡市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '攀枝花',
                price: '220',
                time: '5~7天'
              },
              {
                label: '乐山市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '南充市',
                price: '200',
                time: '5~7天'
              },
              {
                label: '内江市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '遂宁市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '广安市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '泸州市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '达州市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '眉山市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '宜宾市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '雅安市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '资阳市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '彭州市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '崇州市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '都江堰',
                price: '220',
                time: '5~7天'
              },
              {
                label: '广汉市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '什邡市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '绵竹市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '江油市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '峨眉山',
                price: '220',
                time: '5~7天'
              },
              {
                label: '华蓥市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '万源市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '简阳市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '西昌市',
                price: '220',
                time: '7~10天'
              },
            ]
          },
          {
            label: '吉林省',
            children: [{
                label: '长春市',
                price: '200',
                time: '5~7天'
              },
              {
                label: '吉林市',
                price: '200',
                time: '5~7天'
              },
              {
                label: '通化市',
                price: '240',
                time: '7~10天'
              },
              {
                label: '双辽市',
                price: '240',
                time: '7~10天'
              },
              {
                label: '延吉市',
                price: '240',
                time: '7~10天'
              },
              {
                label: '图们市',
                price: '240',
                time: '7~10天'
              },
            ]
          },
          {
            label: '湖南省',
            children: [{
                label: '长沙市',
                price: '100',
                time: '3~5天'
              },
              {
                label: '株洲市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '湘潭市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '邵阳市',
                price: '130',
                time: '3~5天'
              },
              {
                label: '怀化市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '岳阳市',
                price: '130',
                time: '3~5天'
              },
              {
                label: '益阳市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '衡阳市',
                price: '100',
                time: '3~5天'
              },
              {
                label: '永州市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '娄底市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '张家界',
                price: '160',
                time: '3~5天'
              },
              {
                label: '常德市',
                price: '140',
                time: '3~5天'
              },
            ]
          },
          {
            label: '新疆',
            children: [{
                label: '乌鲁木齐',
                price: '220',
                time: '7~10天'
              },
              {
                label: '喀什市',
                price: '280',
                time: '10~12天'
              },
              {
                label: '吐鲁番',
                price: '350',
                time: '10~12天'
              },
            ]
          },
          {
            label: '西藏',
            children: [{
                label: '拉萨市',
                price: '350',
                time: '10~12天'
              },
              {
                label: '日喀则',
                price: '400',
                time: '10~12天'
              },
            ]
          },
          {
            label: '湖北省',
            children: [{
                label: '武汉市',
                price: '130',
                time: '3~5天'
              },
              {
                label: '襄阳市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '宜昌市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '黄石市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '鄂州市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '随州市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '荆州市',
                price: '130',
                time: '3~5天'
              },
              {
                label: '荆门市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '十堰市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '孝感市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '黄冈市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '咸宁市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '大冶市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '丹江口',
                price: '180',
                time: '5~7天'
              },
              {
                label: '洪湖市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '石首市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '松滋市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '宜都市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '当阳市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '枝江市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '老河口',
                price: '180',
                time: '5~7天'
              },
              {
                label: '枣阳市',
                price: '180',
                time: '5~7天'
              },
            ]
          },
          {
            label: '安徽省',
            children: [{
                label: '合肥',
                price: '100',
                time: '3~5天'
              },
              {
                label: '蚌埠',
                price: '150',
                time: '3~5天'
              },
              {
                label: '阜阳',
                price: '150',
                time: '3~5天'
              },
              {
                label: '安庆',
                price: '120',
                time: '3~5天'
              },
              {
                label: '六安',
                price: '150',
                time: '3~5天'
              },
              {
                label: '淮北',
                price: '180',
                time: '5~7天'
              },
              {
                label: '芜湖',
                price: '150',
                time: '5~7天'
              },
              {
                label: '黄山',
                price: '180',
                time: '5~7天'
              },
              {
                label: '淮南',
                price: '180',
                time: '5~7天'
              },
              {
                label: '铜陵',
                price: '180',
                time: '5~7天'
              },
              {
                label: '池州',
                price: '150',
                time: '5~7天'
              },
              {
                label: '宁国',
                price: '180',
                time: '5~7天'
              },
              {
                label: '宣城',
                price: '180',
                time: '5~7天'
              },
            ]
          },
          {
            label: '黑龙江省',
            children: [{
                label: '哈尔滨',
                price: '200',
                time: '5~7天'
              },
              {
                label: '大庆市',
                price: '220',
                time: '5~7天'
              },
              {
                label: '齐齐哈尔',
                price: '240',
                time: '7~10天'
              },
              {
                label: '佳木斯',
                price: '240',
                time: '7~10天'
              },
              {
                label: '黑河市',
                price: '260',
                time: '7~10天'
              },
              {
                label: '绥化市',
                price: '260',
                time: '7~10天'
              },
              {
                label: '绥芬河',
                price: '300',
                time: '7~10天'
              },
            ]
          },
          {
            label: '浙江省',
            children: [{
                label: '杭州市',
                price: '130',
                time: '3~5天'
              },
              {
                label: '台州市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '温州市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '宁波市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '金华市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '瑞安市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '东阳市',
                price: '160',
                time: '3~5天'
              },
            ]
          },
          {
            label: '宁夏',
            children: [{
                label: '银川市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '吴忠市',
                price: '260',
                time: '7~10天'
              },
            ]
          },
          {
            label: '海南省',
            children: [{
                label: '海口市',
                price: '130',
                time: '3~5天'
              },
              {
                label: '三亚市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '文昌市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '万宁市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '陵水市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '乐东市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '昌江市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '东方市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '琼海市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '登海市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '临高市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '瞻川市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '定安市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '屯昌市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '琼中市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '白沙市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '保亭市',
                price: '180',
                time: '5~7天'
              },
            ]
          },
          {
            label: '河南省',
            children: [{
                label: '郑州市',
                price: '120',
                time: '3~5天'
              },
              {
                label: '洛阳市',
                price: '130',
                time: '3~5天'
              },
              {
                label: '安阳市',
                price: '150',
                time: '3~5天'
              },
              {
                label: '开封市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '漯河市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '新乡市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '周口市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '三门峡',
                price: '160',
                time: '5~7天'
              },
              {
                label: '焦作市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '平顶山',
                price: '160',
                time: '5~7天'
              },
              {
                label: '信阳市',
                price: '140',
                time: '5~7天'
              },
              {
                label: '南阳市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '鹤壁市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '濮阳市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '许昌市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '商丘市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '驻马店',
                price: '160',
                time: '3~5天'
              },
              {
                label: '新郑市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '新密市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '登封市',
                price: '160',
                time: '5~7天'
              },
              {
                label: '汝州市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '舞钢市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '林州市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '卫辉市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '沁阳市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '孟州市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '邓州市',
                price: '180',
                time: '5~7天'
              },
              {
                label: '永城市',
                price: '180',
                time: '5~7天'
              },
            ]
          },
          {
            label: '辽宁省',
            children: [{
                label: '沈阳市',
                price: '190',
                time: '7~10天'
              },
              {
                label: '大连市',
                price: '200',
                time: '7~10天'
              },
              {
                label: '锦州市',
                price: '220',
                time: '7~10天'
              },
              {
                label: '营口市',
                price: '180',
                time: '7~10天'
              },
              {
                label: '铁岭市',
                price: '220',
                time: '7~10天'
              },
              {
                label: '朝阳市',
                price: '300',
                time: '7~10天'
              },
              {
                label: '葫芦岛',
                price: '260',
                time: '7~10天'
              },
            ]
          },
          {
            label: '江西省',
            children: [{
                label: '南昌市',
                price: '120',
                time: '3~5天'
              },
              {
                label: '九江市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '赣州市',
                price: '120',
                time: '3~5天'
              },
              {
                label: '吉安市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '鹰潭市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '上饶市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '萍乡市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '景德镇',
                price: '160',
                time: '3~5天'
              },
              {
                label: '新余市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '宜春市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '抚州市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '乐平市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '瑞昌市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '贵溪市',
                price: '180',
                time: '3~5天'
              },
              {
                label: '瑞金市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '南康市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '井冈山',
                price: '180',
                time: '3~5天'
              },
              {
                label: '丰城市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '樟树市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '高安市',
                price: '180',
                time: '3~5天'
              },
              {
                label: '德兴市',
                price: '180',
                time: '3~5天'
              },
            ]
          },
          {
            label: '福建省',
            children: [{
                label: '福州市',
                price: '120',
                time: '3~5天'
              },
              {
                label: '厦门市',
                price: '120',
                time: '3~5天'
              },
              {
                label: '三明市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '泉州市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '莆田市',
                price: '140',
                time: '3~5天'
              },
              {
                label: '漳州市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '南平市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '龙岩市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '宁德市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '福清市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '长乐市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '晋江市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '永安市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '龙海市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '福安市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '福鼎市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '漳平市',
                price: '160',
                time: '3~5天'
              },
              {
                label: '石狮市',
                price: '160',
                time: '3~5天'
              },
            ]
          },
          {
            label: '北京市',
            children: [{
                label: '大兴区',
                price: '150',
                time: '3~5天'
              },
              {
                label: '密云县',
                price: '200',
                time: '3~5天'
              },
            ]
          },
          {
            label: '特区',
            children: [{
                label: '澳门',
                price: '250',
                time: '3~5天'
              },
              {
                label: '香港',
                price: '250',
                time: '3~5天'
              },
            ]
          },
          {
            label: '天津市',
            children: [{
              label: '天津市',
              price: '150',
              time: '3~5天'
            }, ]
          },
          {
            label: '上海市',
            children: [{
              label: '上海市',
              price: '120',
              time: '3~5天'
            }, ]
          },
          {
            label: '重庆市',
            children: [{
              label: '重庆市',
              price: '150',
              time: '3~5天'
            }, ]
          },
        ],


      }
    },
    created() {
      this.init()

    },
    methods: {
      init() {

      },
      // 点击切换查看服务说明
      changeServiceDesc(val){
        switch(val){
          case 0: this.activeNum = val;
          break;
          case 1: this.activeNum = val;
          break;
          case 2: this.activeNum = val;
          break;
        }
      },
      // 查询报价
      query() {
        if (this.form.squareNumber == '' || this.form.price == '') {
          this.$message.error('请填写完整再查询');
        } else {
          this.openDialog = !this.openDialog;
        }
      },
      // 更换地址
      changeAddr(val) {
        if(val.length > 0){
          const pathLabels = this.$refs.cascader.getCheckedNodes()[0].pathLabels;
          this.form.end = pathLabels[0] + '-' + pathLabels[1];
          this.form.price = this.$refs.cascader.getCheckedNodes()[0].data.price;
        }else{
          this.form.end = "";
          this.form.price = 0;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/view/mall/logisticsDistribution/logisticsDistribution";
</style>
